import About from 'profiles/components/Profile/About'
import Header from 'profiles/components/Profile/Header'
import usePublicProfile from 'profiles/hooks/usePublicProfile'

import styles from 'profiles/styles/components/ProfileCard.module.css'

const getCoverPhotoURL = (profile) => {
  const { teamMember } = profile
  const {
    coverPhotoUrl,
    smallCoverPhotoUrl,
    mediumCoverPhotoUrl,
    largeCoverPhotoUrl,
  } = teamMember

  if (smallCoverPhotoUrl && mediumCoverPhotoUrl && largeCoverPhotoUrl)
    return largeCoverPhotoUrl
  if (coverPhotoUrl) return coverPhotoUrl
  return ''
}

const WideProfile = () => {
  const { data: profile } = usePublicProfile()
  const coverPhotoUrl = getCoverPhotoURL(profile)

  return (
    <div className={styles.WideProfileContainer}>
      <div
        className={styles.WideLeftContainer}
        style={{
          background: `url("${coverPhotoUrl}") center no-repeat`,
          backgroundSize: `cover`,
        }}
      >
        {profile.teamMember.quote && (
          <div className={styles.WideQuoteContainer}>
            <div className={styles.LargeQuote}>“</div>
            <div
              className={styles.WideQuote}
              dangerouslySetInnerHTML={{ __html: profile.teamMember.quote }}
            />
          </div>
        )}
      </div>
      <div className={styles.WideRightContainer}>
        <div className={styles.WideScrollableContainer}>
          <Header isWide={true} />
          <About isWide={true} />
        </div>
      </div>
    </div>
  )
}

export default WideProfile
