import cx from 'clsx'
import { setOpacity } from 'core/utils/colors'
import usePublicProfile from 'profiles/hooks/usePublicProfile'

import styles from 'profiles/styles/components/ProfileCard.module.css'

interface OnlineStatusIndicatorProps {
  isOnline?: boolean
  isWide: boolean
}

const OnlineStatusIndicator = ({
  isOnline,
  isWide,
}: OnlineStatusIndicatorProps) => {
  return (
    <div
      className={cx(styles.ProfileStatus, {
        [styles.WideProfileStatus]: isWide,
      })}
    >
      <div
        className={cx(styles.ProfileStatusIndicator, {
          [styles.OfflineStatus]: !isOnline,
        })}
      />
      {isOnline ? `Online` : `Offline`} now
    </div>
  )
}

interface HeaderProps {
  isWide: boolean
}

const Header = ({ isWide }: HeaderProps) => {
  const { data: profile } = usePublicProfile()

  return (
    <div
      className={styles.ProfileCardHeader}
      style={
        isWide
          ? {}
          : {
              background: setOpacity(0.7)(
                `#${profile?.embed.theme.backgroundColor || '#94c8ff'}`
              ),
            }
      }
    >
      {!isWide && (
        <div
          className={styles.ProfileImage}
          style={{ backgroundImage: `url(${profile?.teamMember.avatarUrl})` }}
        />
      )}
      <div
        className={isWide ? styles.WideProfileCardHeader : styles.ProfileUser}
        style={{ color: `#${profile?.embed.theme.foregroundColor}` || 'white' }}
      >
        <div
          className={
            isWide ? styles.WideProfileUserName : styles.ProfileUserName
          }
        >
          {profile?.teamMember.name}
        </div>
        <OnlineStatusIndicator
          isOnline={profile?.teamMember.agentStatus.online}
          isWide={isWide}
        />
      </div>
    </div>
  )
}

export default Header
