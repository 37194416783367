import About from 'profiles/components/Profile/About'
import Header from 'profiles/components/Profile/Header'

import Bio from './Bio'

import styles from 'profiles/styles/components/ProfileCard.module.css'

const VerticalProfile = () => {
  return (
    <div className={styles.ProfileCard}>
      <Header isWide={false} />
      <Bio />
      <About />
    </div>
  )
}

export default VerticalProfile
