import cx from 'clsx'
import { setOpacity } from 'core/utils/colors'
import CircleLoader from 'profiles/components/shared/CircleLoader'

import styles from 'profiles/styles/components/ProfileCard.module.css'

const OnlineStatusIndicator = () => {
  return (
    <div className={styles.ProfileStatus}>
      <div
        className={cx(styles.ProfileStatusIndicator, styles.OfflineStatus)}
      />
      Offline now
    </div>
  )
}

interface LoadingProfileProps {
  className?: string
  error?: boolean
}

const LoadingProfile = ({ error, className }: LoadingProfileProps) => {
  const background = `linear-gradient(135deg, #fff, ${setOpacity(0.4)(`#000`)}`

  return (
    <div className={className} style={{ background }}>
      <div className={styles.ProfileCard}>
        <div
          className={styles.ProfileCardHeader}
          style={{
            background: setOpacity(0.7)('#5d6a74'),
          }}
        >
          <div className={styles.ProfileImage} />
          <div className={styles.ProfileUser}>
            <div className={styles.ProfileUserName}>...</div>
            <OnlineStatusIndicator />
          </div>
        </div>
        <div className={styles.ProfileBioContainer}>...</div>
        <div className={styles.ErrorContainer}>
          {error ? (
            `Sorry, we can't seem to find this profile!`
          ) : (
            <CircleLoader />
          )}
        </div>
      </div>
    </div>
  )
}

export default LoadingProfile
