import usePublicProfile from 'profiles/hooks/usePublicProfile'

import styles from 'profiles/styles/components/ProfileCard.module.css'

const Bio = () => {
  const { data: profile } = usePublicProfile()
  const { teamMember } = profile

  if (!teamMember.quote) return <div />

  return <div className={styles.ProfileBioContainer}>{teamMember.quote}</div>
}

export default Bio
