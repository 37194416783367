import cx from 'clsx'
import { setOpacity } from 'core/utils/colors'
import Head from 'next/head'
import { useRouter } from 'next/router'
import LoadingProfile from 'profiles/components/shared/LoadingProfile'
import VerticalProfile from 'profiles/components/VerticalProfile'
import WideProfile from 'profiles/components/WideProfile'
import usePublicProfile from 'profiles/hooks/usePublicProfile'
import ProfileLayout from 'profiles/layout'
import getServerSideProfileProps from 'profiles/lib/getServerSideProps'

import styles from 'profiles/styles/[globalHandle].module.css'

export default function MainProfile() {
  const router = useRouter()

  const { data: profile } = usePublicProfile()

  if (router.isFallback) {
    return <LoadingProfile className={styles.container} />
  }

  if (!profile) {
    throw 404
  }

  const isWideLayout = profile.teamMember.layout === 'WIDE'
  const background = isWideLayout
    ? `#ffffff`
    : `linear-gradient(135deg, #${
        profile.embed.theme.backgroundColor
      }, ${setOpacity(0.4)(`#${profile.embed.theme.backgroundColor}`)}`
  const layout = isWideLayout ? <WideProfile /> : <VerticalProfile />

  return (
    <div
      className={cx(styles.container, { [styles.wideContainer]: isWideLayout })}
      style={{ background }}
    >
      <Head>
        <title>
          {profile.teamMember?.name} | {profile.embed?.organizationName}
        </title>
      </Head>
      {layout}
    </div>
  )
}

MainProfile.getLayout = function MainProfileLayout(page) {
  return <ProfileLayout>{page}</ProfileLayout>
}

export const getServerSideProps = getServerSideProfileProps
