import React from 'react'

import { ensureUri } from 'core/utils/urls'
import usePublicProfile from 'profiles/hooks/usePublicProfile'

import styles from 'profiles/styles/components/ProfileCard.module.css'

const ExtendedProfileHeading = ({
  children,
}: React.PropsWithChildren<unknown>) => (
  <h2 className={styles.DetailsH2}>{children}</h2>
)

const Details = () => {
  const { data: profile } = usePublicProfile()

  return (
    <div>
      {profile?.teamMember.bio && (
        <div
          className={styles.DetailsBio}
          dangerouslySetInnerHTML={{ __html: profile.teamMember.bio }}
        />
      )}

      {profile?.teamMember.languages && (
        <div>
          <ExtendedProfileHeading>Languages</ExtendedProfileHeading>
          <p className={styles.DetailsContent}>
            {profile.teamMember.languages}
          </p>
        </div>
      )}

      {profile?.teamMember.likes && (
        <div>
          <ExtendedProfileHeading>Likes</ExtendedProfileHeading>
          <p className={styles.DetailsContent}>{profile.teamMember.likes}</p>
        </div>
      )}

      {profile?.teamMember.funFact && (
        <div>
          <ExtendedProfileHeading>Fun Fact</ExtendedProfileHeading>
          <p className={styles.DetailsContent}>{profile.teamMember.funFact}</p>
        </div>
      )}

      {profile?.teamMember.educationInfo && (
        <div>
          <ExtendedProfileHeading>Education</ExtendedProfileHeading>
          <p className={styles.DetailsContent}>
            {profile.teamMember.educationInfo}
          </p>
        </div>
      )}

      {profile?.teamMember.blogUrl && (
        <div>
          <ExtendedProfileHeading>Blog</ExtendedProfileHeading>
          <p className={styles.DetailsContent}>
            <a
              href={ensureUri(profile.teamMember.blogUrl)}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {profile.teamMember.blogUrl}
            </a>
          </p>
        </div>
      )}

      {profile?.teamMember.podcastUrl && (
        <div>
          <ExtendedProfileHeading>Podcast</ExtendedProfileHeading>
          <p className={styles.DetailsContent}>
            <a
              href={ensureUri(profile.teamMember.podcastUrl)}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {profile.teamMember.podcastUrl}
            </a>
          </p>
        </div>
      )}
    </div>
  )
}

export default Details
