import cx from 'clsx'
import Location from 'profiles/components/shared/Location'

import ButtonBar from './ButtonBar'
import Details from './Details'
import SocialMediaBar from './SocialMediaBar'

import styles from 'profiles/styles/components/ProfileCard.module.css'

const About = ({ isWide = false }) => {
  return (
    <div
      className={cx(styles.AboutContainer, {
        [styles.WideAboutContainer]: isWide,
      })}
    >
      <Location />
      <ButtonBar />
      <SocialMediaBar />
      <Details />
    </div>
  )
}

export default About
