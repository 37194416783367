import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  PinterestIcon,
  SkypeIcon,
  SoundCloudIcon,
  TwitterIcon,
} from 'core/constants/icons'
import usePublicProfile from 'profiles/hooks/usePublicProfile'

import styles from 'profiles/styles/components/ProfileCard.module.css'

type MakeUrl = (handle: string) => string

const makeFacebookUrl: MakeUrl = (handle) => `https://facebook.com/${handle}`
const makeTwitterUrl: MakeUrl = (handle) => `https://twitter.com/${handle}`
const makeLinkedInUrl: MakeUrl = (handle) =>
  `https://www.linkedin.com/in/${handle}`
const makeInstagramUrl: MakeUrl = (handle) => `https://instagram.com/${handle}`
const makeSoundCloudUrl: MakeUrl = (handle) =>
  `https://soundcloud.com/${handle}`
const makePinterestUrl: MakeUrl = (handle) => `https://pinterest.com/${handle}`
const makeSkypeUrl: MakeUrl = (handle) => `skype:${handle}?call`

const SocialButtonLink = (
  props: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
) => <a {...props} target="_blank" rel="noopener noreferrer nofollow" />

const SocialMediaBar = () => {
  const { data: profile } = usePublicProfile()

  return (
    <div
      className={styles.SocialMediaBar}
      style={{ color: profile?.embed.theme.foregroundColor }}
    >
      {profile?.teamMember.facebookHandle && (
        <SocialButtonLink
          href={makeFacebookUrl(profile.teamMember.facebookHandle)}
        >
          <FacebookIcon
            fill={`#${profile?.embed.theme.backgroundColor}`}
            width={20}
          />
        </SocialButtonLink>
      )}
      {profile?.teamMember.twitterHandle && (
        <SocialButtonLink
          href={makeTwitterUrl(profile.teamMember.twitterHandle)}
        >
          <TwitterIcon
            fill={`#${profile?.embed.theme.backgroundColor}`}
            width={20}
          />
        </SocialButtonLink>
      )}
      {profile?.teamMember.linkedInHandle && (
        <SocialButtonLink
          href={makeLinkedInUrl(profile.teamMember.linkedInHandle)}
        >
          <LinkedInIcon
            fill={`#${profile?.embed.theme.backgroundColor}`}
            width={20}
          />
        </SocialButtonLink>
      )}
      {profile?.teamMember.soundCloudHandle && (
        <SocialButtonLink
          href={makeSoundCloudUrl(profile.teamMember.soundCloudHandle)}
        >
          <SoundCloudIcon
            fill={`#${profile?.embed.theme.backgroundColor}`}
            width={20}
          />
        </SocialButtonLink>
      )}
      {profile?.teamMember.instagramHandle && (
        <SocialButtonLink
          href={makeInstagramUrl(profile.teamMember.instagramHandle)}
        >
          <InstagramIcon
            fill={`#${profile?.embed.theme.backgroundColor}`}
            width={20}
          />
        </SocialButtonLink>
      )}
      {profile?.teamMember.pinterestHandle && (
        <SocialButtonLink
          href={makePinterestUrl(profile.teamMember.pinterestHandle)}
        >
          <PinterestIcon
            fill={`#${profile?.embed.theme.backgroundColor}`}
            width={20}
          />
        </SocialButtonLink>
      )}
      {profile?.teamMember.skypeHandle && (
        <SocialButtonLink href={makeSkypeUrl(profile.teamMember.skypeHandle)}>
          <SkypeIcon
            fill={`#${profile?.embed.theme.backgroundColor}`}
            width={20}
          />
        </SocialButtonLink>
      )}
    </div>
  )
}

export default SocialMediaBar
